import React, { Component } from "react";
import "./loading.css";

class Loading extends Component {
  render() {
    return <div className="box-loader"></div>;
  }
}

export default Loading;
